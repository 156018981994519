// Imports
import HomePreview from "../../homepreview/HomePreview";
import HomeBanner from "../../homebanner/HomeBanner";
import React from "react";

function HomePage(){


    return (
        <>
        <HomeBanner/>
        <HomePreview/>
        </>
    );
}

export default HomePage;